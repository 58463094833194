<div class="container">
  <span class="material-icons-outlined close-icon" (click)="close()">close</span>
  <div class="profile-header text-center">
    <p class="pb-0">my profile</p>
  </div>

  <div class="profile-body">
    <div class="profile-image" [ngClass]="{'cursor-pointer': editProfile}">
      <ng-container *ngIf="userProfileData.photo">
        <img [src]="userProfileData.photo" id="profileImage" (click)="editProfile?showImageMenu=!showImageMenu:false">
      </ng-container>
      <ng-container *ngIf="!userProfileData.photo">
        <div id="profileImage" class="profileImage-div"(click)="editProfile?showImageMenu=!showImageMenu:false">{{userProfileData?.name?.charAt(0)}}</div>
      </ng-container>
    </div>
    <span class="d-flex align-items-center update-type">
      <div class="input-options" [hidden]="!showImageMenu" id="imageChooseMenu" [class.removebutton]="!userProfileData.photo">
        <ul>
          <li>
            <label>
              <input type="file" class="hide-input" (change)="uploadPhoto($event)" multiple #uploadFile
              accept="image/png, image/jpeg" name="uploadFile" />
                Choose existing photo2
            </label><br>
            <label (click)="removePhoto()" *ngIf="userProfileData.photo">
              Remove photo
            </label>
          </li>
        </ul>
      </div>
    </span>

    <div class="profile-data">
      <form class="form" #myForm="ngForm">

        <div class="row row-field mb-1">
          <div class="col-md-12">
            <div class="input-icons" [ngClass]="{'has-error': formSubmitted && (fullName.invalid || !userProfileData.name?.trim() || !onlyCharacters(userProfileData.name))}">
              <div class="row mb-0">
                <div class="col-md-6 col-6">
                  <label for="fullName" class="field-label">Full Name</label>
                </div>
                <div class="col-md-6 col-6 field-message text-danger text-right" *ngIf="formSubmitted && (fullName.invalid || !userProfileData.name?.trim())">
                  Full name is required
                </div>
                <div class="col-md-6 col-6 field-message text-danger text-right" *ngIf="fullName.valid && !onlyCharacters(userProfileData.name)">
                  Only alphabets are allowed
                </div>
              </div>
              <input type="text" id="fullName" name="fullName" #fullName="ngModel" [disabled]="!editProfile"
                [(ngModel)]="userProfileData.name" required placeholder="Full name" [maxlength]="20" class="form-control">
            </div>
          </div>
        </div>

        <div class="row row-field mb-1">
          <div class="col-md-12">
            <div class="input-icons" [ngClass]="{'has-error': formSubmitted && (email.invalid || !matchPattern() || !userProfileData.email?.trim())}">
              <div class="row mb-0">
                <div class="col-md-6 col-6">
                  <label for="email" class="field-label">Email</label>
                </div>
                <div class="col-md-6 col-6 field-message text-danger text-right" *ngIf="formSubmitted && (!userProfileData.email?.trim() || email.invalid)">
                  Email is required
                </div>
                <div class="col-md-6 col-6 field-message text-danger text-right" *ngIf="formSubmitted && (userProfileData.email?.trim() && !matchPattern())">
                  Incorrect email format
                </div>
              </div>
              <input type="text" id="email" name="email" #email="ngModel" [(ngModel)]="userProfileData.email" required
                placeholder="name@email.com" class="form-control" [disabled]="true">
            </div>
          </div>
        </div>

        <div class="row row-field mb-1">
          <div class="col-md-12">
            <div class="input-icons" [ngClass]="{'has-error': formSubmitted && (companyName.invalid || !userProfileData.orgName?.trim()|| !onlyCharacters(userProfileData.orgName))}">
              <div class="row mb-0">
                <div class="col-md-6 col-6">
                  <label for="companyName" class="field-label">Company Name</label>
                </div>
                <div class="col-md-6 col-6 field-message text-danger text-right" *ngIf="formSubmitted && (companyName.invalid || !userProfileData.orgName?.trim())">
                  Company name is required
                </div>
                <div class="col-md-6 col-6 field-message text-danger text-right" *ngIf="companyName.valid && !onlyCharacters(userProfileData.orgName)">
                  Only alphabets are allowed
                </div>
              </div>
              <input type="text" id="companyName" name="companyName" #companyName="ngModel" [disabled]="true"
                [(ngModel)]="userProfileData.orgName" required placeholder="Company name" class="form-control">
            </div>
          </div>
        </div>

        <div class="row row-field mb-1">
          <div class="col-md-12">
            <div class="input-icons">
              <div class="row mb-0">
                <div class="col-md-6 col-6">
                  <label for="roleName" class="field-label">Role</label>
                </div>
              </div>
              <input type="text" id="roleName" name="roleName" [value]="roleName" [disabled]="true" class="form-control">
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-between" style="padding: 0 30px;">
          <button type="button" name="cancel" class="submit-btn-outline" *ngIf="editProfile"
            (click)="cancel()">Cancel</button>
          <button type="button" name="go" class="submit-btn" (click)="$event.preventDefault();updateProfile(myForm)"
          *ngIf="editProfile">Save Changes</button>
            <button type="button" name="edit" class="submit-btn-outline  btn-centered" *ngIf="!editProfile"
            (click)="editProfile=true;">Edit profile</button>
        </div>

      </form>
    </div>
  </div>
</div>
