import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'searchInChat',
})
export class SearchInChatPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer){}

  transform(value: string, searchString: string, applyFilter: boolean) {
    if (applyFilter && value && searchString) {

      // Match in a case insensitive maneer
      const re = new RegExp(searchString.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi');
      const match = value.match(re);

      // If there's no match, just return the original value.
      if (!match) {
        return value;
      }

      const result = value.replace(re, '<mark style="background: yellow;" class="searched-marked">' + match[0] + '</mark>');
      return this.sanitizer.bypassSecurityTrustHtml(result);
    }
    return value;
  }
}
