// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: "https://api.engage.truventor.ai/",
  socketUrl: "wss://api.engage.truventor.ai/",
  meetingUrl:"https://api.engage.truventor.ai/",
  // apiUrl: "http://localhost:3988/",
  // socketUrl: "wss://localhost:3988/",
  // meetingUrl: "http://localhost:3988/",
  // jitsiDomain: "8x8.vc",
  jitsiDomain: "meet.truventor.ai",
  tOneUrl: 'https://truventor-one-fe-app.azurewebsites.net',
  tOneAPIUrl: 'https://www.dev.api.one.truventor.ai/v1/auth/validate.token',
  tOneAdminUrl: 'https://truventor-admin-fe-app.azurewebsites.net',
  zoomUserId: 'ZqaUV2CbQHOItxifcYUO8w',
  clientKeyOrSDKKey: 'uQA3OKGSSjic9pidjFCWqA'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
