<div class="container mb-4">
  <div class="dialog-title">
    <p>{{title}}</p>
  </div>
  <div class="dialog-body">
    <p [innerHTML]="description"></p>
  </div>
  <div class="dialog-btns">
    <button type="button" name="cancel" class="submit-btn-outline" (click)="$event.preventDefault();activeModal.close(false)">Cancel</button>
    <button type="button" name="go" class="submit-btn" (click)="$event.preventDefault();activeModal.close(true)">{{okButtonText}}</button>
  </div>
</div>
