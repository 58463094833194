import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'calendarMeetingDaywise'
})
export class CalendarMeetingDaywisePipe implements PipeTransform {

  transform(meetingList:Array<any>, date) {
    if(meetingList && meetingList.length && date){
      return meetingList.filter(f => moment(f.start).format('DD MM yy') == moment(date).format('DD MM yy'));
    }else if(meetingList && meetingList.length){
      return meetingList;
    }else{
      return [];
    }
  }

}
