import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from '@common-components/page-not-found/page-not-found.component';
import { DashboardAuthGuard } from '@guards/dashboard.guard';
import { LoginAuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: "auth",
  },
  {
    path: "auth",
    loadChildren: () => import("@modules/auth/auth.module").then((m) => m.AuthModule),
    canLoad: [LoginAuthGuard],
  },
  {
    path: "dashboard",
    loadChildren: () => import("@modules/dashboard/dashboard.module").then((m) => m.DashboardModule),
    canLoad: [DashboardAuthGuard]
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'page-404',
  },
  {
    path: 'page-404',
    component: PageNotFoundComponent,
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
