import { Component, OnInit, Output, EventEmitter, NgZone } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-speech-to-text',
  templateUrl: './speech-to-text.component.html',
  styleUrls: ['./speech-to-text.component.scss'],
})
export class SpeechToTextComponent implements OnInit {
  public startRecognition: boolean = false;
  @Output() getSpeechToText: EventEmitter<any> = new EventEmitter<any>();
  public timeout;

  unsubscribe$: Subscription;
  recognition;
  msg;

  constructor(private zone: NgZone) {}

  ngOnInit(): void {}

  startService() {
    const SpeechRecognition = window['webkitSpeechRecognition'] || window['SpeechRecognition'];
    if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
      // speech recognition API supported

      this.recognition = new SpeechRecognition();
      this.recognition.continuous = true;
      this.recognition.lang = 'en-US';
      this.recognition.interimResults = true;
      this.recognition.maxAlternatives = 3;

      this.recognition.start();
      this.recognition.onresult = (event) => {
        let isFinal = event.results[0].isFinal;
        if (!isFinal) {
          this.zone.run(() => {
            this.msg = event.results[0][0].transcript;
            this.getSpeechToText.emit({msg: this.msg, blink: true});
          });
        } else if (isFinal) {
          this.zone.run(() => {
            this.msg = event.results[0][0].transcript;
            this.recognition.stop();
            this.getSpeechToText.emit({msg: this.msg, blink: false});
          });
        }
      };
    } else {
      // speech recognition API not supported
     console.log('speech recognition API not supported!!');
    }
  }

  startStopVoiceRecognition() {
    this.startService();
  }
}
