import { Injectable } from '@angular/core';
// import * as AWS from 'aws-sdk/global';
// import * as S3 from 'aws-sdk/clients/s3';
import {
  BlobServiceClient
} from '@azure/storage-blob';
import { Subject, Observable } from 'rxjs';
import { ToastDisplay } from '../toastr/toast-service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { HttpClient } from '@angular/common/http';
import { CommonService } from './common.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class UploadFilesService {
  // ACCESS_KEY_ID: string = 'AKIAVFVN2XDKVUOKPMHP';
  // SECRET_ACCESS_KEY: string = '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';
  // REGION: string = 'ap-south-1';
  // BUCKET_NAME: string = 'engage-storage';

  // TODO: This is wrong. Should do it from backend
  AZURE_STORAGE_ACCOUNT_NAME = 'truventorstoragev2';
  AZURE_STORAGE_CONTAINER_NAME = 'engage-storage';
  token = 'sp=racw&st=2024-05-26T18:23:55Z&se=2024-06-10T02:23:55Z&sv=2022-11-02&sr=c&sig=7ZgzYYiu0BQlGxEI610lrA0GChBZ4VVyvcJhvDW1rhc%3D'
  // :SAS tokens donot have lifetime validity and only specific IPs have access. Even there is no way, better to get SAS tokens from backend
  AZURE_STORAGE_SAS_TOKEN_URL = `https://${this.AZURE_STORAGE_ACCOUNT_NAME}.blob.core.windows.net/engage-storage?${this.token}`

  uploadURL = new Subject<string>();
  returnURL: string;
  fileExtentions = [
    'mp3',
    'mp4',
    '3gp',
    'mkv',
    'pdf',
    'doc',
    'docx',
    'ogg',
    'amr',
    'mpeg',
    'jpeg',
    'jpg',
    'png',
    'acc',
    'webp',
    'audio',
    'xlsx',
    'xls',
    'dxf',
    'step',
  ];
  sharedKeyCredential: any;
  containerClient: any;
  blobServiceClient: any;

  constructor(
    private toastr: ToastDisplay,
    private loader: NgxUiLoaderService,
    private httpClient: HttpClient,
    private commonService: CommonService,
    private authService: AuthService
  ) {
    this.blobServiceClient = new BlobServiceClient(this.AZURE_STORAGE_SAS_TOKEN_URL);
    this.containerClient =
      this.blobServiceClient.getContainerClient(this.AZURE_STORAGE_CONTAINER_NAME);
  }

  getFormattedTimestamp = () => {
    let date = new Date();
    let d = date.getDate();
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    let h = date.getHours();
    let min = date.getMinutes();
    let s = date.getSeconds();
    let ms = date.getMilliseconds();
    return y + d + m + h + min + s + ms;
  };

  async uploadBase64(url: string, folder: string, type) {
    (window as any).global = window;
    // @ts-ignore
    window.Buffer = window.Buffer || require('buffer').Buffer;

    const buf = new Buffer(
      url.replace(/^data:image\/\w+;base64,/, ''),
      'base64'
    );

    // const bucket = new S3({
    //   accessKeyId: this.ACCESS_KEY_ID,
    //   secretAccessKey: this.SECRET_ACCESS_KEY,
    //   region: this.REGION,
    // });

    // const params = {
    //   Bucket: this.BUCKET_NAME,
    //   Key: folder + '/' + 'attachment' + this.getFormattedTimestamp() + type,
    //   Body: buf,
    //   ContentEncoding: 'base64',
    //   ContentType: 'image/jpeg',
    // };

    const blobName = folder + '/' + 'attachment' + this.getFormattedTimestamp() + type;
    const blockBlobClient = this.containerClient.getBlockBlobClient(blobName);
    const bucket = blockBlobClient;
    this.loader.start();

    // return bucket
    //   .upload(params, function (err, data, d = this.uploadURL) {
    //     if (err) {
    //       //  console.log('There was an error uploading your file: ', err);
    //       // return false;
    //     }
    //     //  console.log('Successfully uploaded file.', data);
    //     this.loader.stop();
    //     // return true;
    //   })
    //   .promise();
    try {
      await bucket.upload(buf, buf.length, {
        blobHTTPHeaders: {
          blobContentType: 'image/jpeg', // set the appropriate content type
          blobContentEncoding: 'base64'
        },
      });
      this.loader.stop();
      return { success: true, url: blockBlobClient.url };
    } catch (error) {
      this.loader.stop();
      return { success: false, error: error.message };
    }
  }

  uploadInspectionLibraryFiles(formData:FormData, sessionId:string): Observable<any>{
    return this.httpClient.patch(
      this.commonService.envUrl() + `inspection/library/${sessionId}`,
      formData
    );
  }

  fetchLibraryItems(sessionId:string):Observable<any>{
    return this.httpClient.get(
      this.commonService.envUrl() + `inspection/get_library/${sessionId}`
    );
  }

  checkFileUploadCriteria(file:File, errorFileName?):boolean {
    if (
      this.calculateSize(file.type, file.size, true) &&
      this.isFileSupported(file.type, true)) {
        return true;
    }
    else{
      if (!this.isFileSupported(file.type, true)) {
        if (!file.name) {
          this.toastr.showInformation('File is not supported!');
        } else {
          this.toastr.showInformation(
            'File: ' +
            (errorFileName ? errorFileName : file.name) +
            ' is not supported!'
          );
        }
        return false;
      }
      this.toastr.showError(
        'Size exceeded for file: ' + (errorFileName ? errorFileName : file.name)
      );
      return false;
    }
  }

  async uploadFile(file, folder, type, errorFileName?): Promise<any> {
    // var Obj = this; //this of angular comopnent

    if (
      this.calculateSize(file.name, file.size) &&
      this.isFileSupported(file.name)
    ) {
      let contentType = 'application/octet-stream';
      // const bucket = new S3({
      //   accessKeyId: this.ACCESS_KEY_ID,
      //   secretAccessKey: this.SECRET_ACCESS_KEY,
      //   region: this.REGION,
      // });
      // const params = {
      //   Bucket: this.BUCKET_NAME,
      //   Key: folder + '/' + 'attachment' + this.getFormattedTimestamp() + type,
      //   Body: file,
      //   ContentType: contentType,
      // };
      const blobName = folder + '/' + 'attachment' + this.getFormattedTimestamp() + type;
      const blockBlobClient = this.containerClient.getBlockBlobClient(blobName);
      const bucket = blockBlobClient;
      this.loader.start();
      // return bucket
      //   .upload(params, function (err, data, d = this.uploadURL) {
      //     if (err) {
      //       //  console.log('There was an error uploading your file: ', err);
      //       // return false;
      //     }
      //     //  console.log('Successfully uploaded file.', data);
      //     this.loader.stop();
      //     // return true;
      //   })
      //   .promise();

      try {
        await bucket.upload(file, file.size, {
          blobHTTPHeaders: {
            blobContentType: contentType, // set the appropriate content type
          },
        });
        this.loader.stop();
        return { success: true, url: blockBlobClient.url };
      } catch (error) {
        this.loader.stop();
        return { success: false, error: error.message };
      }
    } else {
      if (!this.isFileSupported(file.name)) {
        if (!file.name) {
          this.toastr.showInformation('File is not supported!');
        } else {
          this.toastr.showInformation(
            'File: ' +
            (errorFileName ? errorFileName : file.name) +
            ' is not supported!'
          );
        }
        return 'size-error';
      }
      this.toastr.showError(
        'Size exceeded for file: ' + (errorFileName ? errorFileName : file.name)
      );
      return 'size-error';
    }

    //for upload progress
    // bucket.upload(params).on('httpUploadProgress', function (evt) {
    //       console.log(evt.loaded + ' of ' + evt.total + ' Bytes');
    //   }).send(function (err, data) {
    //       if (err) {
    //           console.log('There was an error uploading your file: ', err);
    //           return false;
    //       }
    //       console.log('Successfully uploaded file.', data);
    //       return true;
    //   });
  }

  // getPreSignedURL(fileName): string {
  //   var credentials = {
  //     accessKeyId: this.ACCESS_KEY_ID,
  //     secretAccessKey: this.SECRET_ACCESS_KEY,
  //   };
  //   AWS.config.update({ credentials: credentials, region: this.REGION });
  //   var s3 = new S3();

  //   var presignedGETURL = s3.getSignedUrl('getObject', {
  //     Bucket: this.BUCKET_NAME,
  //     Key: fileName, //filename
  //     Expires: 85000, //time to expire in seconds
  //   });

  //   return presignedGETURL;
  // }

  async getPreSignedURL(fileName: string): Promise<any> {
    fileName = fileName.split('?X-Amz-Algorithm')[0];
    let temp = fileName.split('.com/');
    const result = await this.getSingedUrlFromApi(
      temp[temp.length - 1]
    ).toPromise();
    console.log('result', result);
    return result;
  }

  getSingedUrlFromApi(filename: string): Observable<any> {
    return this.authService.getSignedUrl(filename);
  }

  calculateSize(fileType, fileSize, isProfile?): boolean {
    //byte to mb..
    let size = fileSize * 0.000001;

    if (isProfile) {
      if (
        size > 5 &&
        (fileType.toString().toLowerCase().includes('image') ||
          fileType.toString().toLowerCase().includes('img'))
      ) {
        return false;
      }
    } else {
      if (
        size > 50 &&
        (fileType.toString().toLowerCase().includes('pdf') ||
          fileType.toString().toLowerCase().includes('docx') ||
          fileType.toString().toLowerCase().includes('doc') ||
          fileType.toString().toLowerCase().includes('amr') ||
          fileType.toString().toLowerCase().includes('mpeg') ||
          fileType.toString().toLowerCase().includes('ogg') ||
          fileType.toString().toLowerCase().includes('xlsx') ||
          fileType.toString().toLowerCase().includes('xls') ||
          fileType.toString().toLowerCase().includes('acc'))
      ) {
        return false;
      } else if (
        size > 300 &&
        (fileType.toString().toLowerCase().includes('mp4') ||
          fileType.toString().toLowerCase().includes('3gpp') ||
          fileType.toString().toLowerCase().includes('mkv') ||
          fileType.toString().toLowerCase().includes('dxf') ||
          fileType.toString().toLowerCase().includes('step'))
      ) {
        return false;
      } else if (
        size > 30 &&
        (fileType.toString().toLowerCase().includes('webp') ||
          fileType.toString().toLowerCase().includes('png') ||
          fileType.toString().toLowerCase().includes('jpeg'))
      ) {
        return false;
      }
    }
    return true;
  }

  isFileSupported(fileName, isProfile?) {
    if (fileName) {
      if (isProfile) {
        if (
          fileName.toString().toLowerCase().includes('image') ||
          fileName.toString().toLowerCase().includes('img')
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return (
          this.fileExtentions.findIndex((f) =>
            fileName.toString().toLowerCase().includes(f)
          ) > -1
        );
      }
    }
    return false;
  }

  storeAttachment(chatID, payload) {
    return this.httpClient.post(
      this.commonService.envUrl() + `bot/attachment?chatID=${chatID}`,
      payload
    );
  }

  //only for user profile image
  async uploadFileProfile(file, folder, type): Promise<any> {
    // var Obj = this; //this of angular comopnent

    if (
      this.calculateSize(file.type, file.size, true) &&
      this.isFileSupported(file.type, true)
    ) {
      let data = [];
      data['file'] = file;
      data['folder'] = folder;
      data['type'] = type;
      // let contentType = "application/octet-stream";
      // const bucket = new S3({
      //   accessKeyId: this.ACCESS_KEY_ID,
      //   secretAccessKey: this.SECRET_ACCESS_KEY,
      //   region: this.REGION,
      // });
      // const params = {
      //   Bucket: this.BUCKET_NAME,
      //   Key: folder + '/' + 'attachment'+this.getFormattedTimestamp()+type,
      //   Body: file,
      //   ContentType: contentType,
      // };
      // this.loader.start();
      // return bucket
      //   .upload(params, function (err, data, d = this.uploadURL) {
      //     if (err) {
      //     //  console.log('There was an error uploading your file: ', err);
      //       // return false;
      //     }
      //   //  console.log('Successfully uploaded file.', data);
      //     this.loader.stop();
      //     // return true;
      //   })
      //   .promise();
      console.log('ks', data);
      let res = await this.authService.updateUserProfile(data);
      return res;
    } else {
      if (!this.isFileSupported(file.type, true)) {
        if (!file.name) {
          this.toastr.showInformation('File is not supported!');
        } else {
          this.toastr.showInformation(
            'File: ' + file.name + ' is not supported!'
          );
        }
        return 'size-error';
      }
      this.toastr.showError('Size exceeded for file: ' + file.name);
      return 'size-error';
    }
  }
}
