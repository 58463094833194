import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { ChangePasswordModal, LoginModal, RegistrationModal, ResetPasswordModal } from '@models/auth';
import { CommonService } from './common.service';

let headers= new HttpHeaders()
  .set('content-type', 'application/json')
  .set('Access-Control-Allow-Origin', '*');

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  constructor(private httpClient: HttpClient, private commonService: CommonService) { }

  validateAuthToken(token) {
    let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
    return this.httpClient.get(this.commonService.envToneAPIUrl(), { headers: headers });
  }

  loginUserPost(data: LoginModal) {
    return this.httpClient.post(this.commonService.envUrl() + 'user/login', data);
  }

  reisterNewUserPost(data: RegistrationModal) {
    return this.httpClient.post(this.commonService.envUrl() + 'user/register', data);
  }

  forgotPasswordPost(email){
    return this.httpClient.post(this.commonService.envUrl() + 'password/forgot', email);
  }

  resetPasswordPost(data : ResetPasswordModal){
    return this.httpClient.post(this.commonService.envUrl() + 'password/reset', data);
  }

  changePasswordPost(data : ChangePasswordModal){
    return this.httpClient.post(this.commonService.envUrl() + `password/change`, data);
  }

  fetchUserProfile() {
    return this.httpClient.get(this.commonService.envUrl() + 'user/profile');
  }

  updateUserProfile(data) {
    return this.httpClient.put(this.commonService.envUrl() + 'user/profile', data);
  }

  refreshToken(){
    return this.httpClient.get(this.commonService.envUrl() + 'token/refresh');
  }

  verifyRegistration(){
    return this.httpClient.put(this.commonService.envUrl() + 'user/verify', {isEmailVerified:true});
  }

  logoutAPI(){
    return this.httpClient.get(this.commonService.envUrl() + 'user/logout');
  }

  // Roles

  getUsers(){
    return this.httpClient.get(this.commonService.envUrl() + 'owner/user.list');
  }

  getRoles(){
    return this.httpClient.get(this.commonService.envUrl() + 'owner/role.list');
  }

  updateRole(body){
    return this.httpClient.put(this.commonService.envUrl() + 'owner/role.change', body);
  }

  getSignedUrl(data) {
    return this.httpClient.post(this.commonService.envUrl() + 'user/generate-signed-url', data);
  }

  uploadFileProfile(data) {
    console.log('data', data);
    return this.httpClient.post(this.commonService.envUrl() + 'user/uploadFileProfile', data);
  }
}
