import jwt_decode from 'jwt-decode';
import * as CryptoJS from 'crypto-js';

export class UtilsHelper {
  public static setObject(key, value) {
    if (this.lsSpaceAvailable(key, value) === true) {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      sessionStorage.setItem(key, JSON.stringify(value));
    }
  }

  public static lsSpaceAvailable(key, value) {
    try {
      localStorage.setItem(key, JSON.stringify(value));
      localStorage.removeItem(key);
      return true;
    } catch (e) {
      return false;
    }
  }

  /**
   *
   * Get token
   * @public
   * @memberof UtilsHelper
   */
  public static getToken() {
    try {
      let token = JSON.parse(localStorage.getItem('token'));
      if (!!token) {
        return token;
      }
      return false;
    } catch (e) {
      if (
        localStorage.getItem('token') == undefined ||
        localStorage.getItem('token') == 'undefined'
      ) {
        this.clearAll();
        location.reload();
      }
    }
  }


  public static getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] == variable) {
        return pair[1];
      }
    }
  }


  public static get IsLoggedIn(): boolean {
    try {
      let urlToken;
      let savedToken;
      savedToken = JSON.parse(localStorage.getItem('token'));
      if(!!savedToken){
        return savedToken;
      }

      urlToken = this.getQueryVariable("token");
      if(!!urlToken){
           UtilsHelper.setObject('token', urlToken);
           const decodedToken: any = UtilsHelper.getDecodedToken();
           const userName = decodedToken.name;
           const email = decodedToken.email;
           const userId = decodedToken.id;
           const userAvatar = decodedToken.userAvatar;
           const orgName = "";//TODO
           UtilsHelper.setObject('userName', userName);
           UtilsHelper.setObject('email', email);
           UtilsHelper.setObject('orgName', orgName);
           UtilsHelper.setObject('userId', userId);
           UtilsHelper.setObject('userAvatar', userAvatar);
           return urlToken;
      }
      return false;
    } catch (e) {
      const token = localStorage.getItem('token');
      return !!token;
    }
  }

  public static getObject(key) {
    if (localStorage.getItem(key)) {
      return JSON.parse(localStorage.getItem(key));
    } else {
      return JSON.parse(sessionStorage.getItem(key));
    }
  }

  public static clearAll() {
    localStorage.clear();
  }

  public static removeObject(key) {
    if (localStorage.getItem(key)) {
      return localStorage.removeItem(key);
    }
  }

  /**
   *  Get login user name
   */
  public static getUserName() {
    let user = localStorage.getItem('userName');
    if (user) {
      return JSON.parse(user);
    } else {
      return '';
    }
  }

  /**
   *  Get Authorization token
   */
  public static getAuthToken() {
    try {
      let token = JSON.parse(localStorage.getItem('token'));
      if (token) {
        return `Bearer ${token}`;
      } else {
        return '';
      }
    } catch (e) {
      if (
        localStorage.getItem('token') == undefined ||
        localStorage.getItem('token') == 'undefined'
      ) {
        this.clearAll();
        location.reload();
      }
    }
  }

  /**
   *  Get Decoded token
   */
  public static getDecodedToken() {
    let token = JSON.parse(localStorage.getItem('token'));
    if (token) {
      try {
        return jwt_decode(token);
      } catch (Error) {
        return null;
      }
    } else {
      return null;
    }
  }

  public static decodedToken(tokenToDecode) {
    try {
      return jwt_decode(tokenToDecode);
    } catch (Error) {
      return null;
    }
  }

  public static encryptData(data) {
    try {
      return CryptoJS.AES.encrypt(JSON.stringify(data), 'this.encryptSecretKey').toString();
    } catch (e) {
      console.log(e);
    }
  }

  public static decryptData(data) {
    try {
      let bytes = CryptoJS.AES.decrypt(data, 'this.encryptSecretKey');
      if (bytes) {
        let stringData = bytes.toString(CryptoJS.enc.Utf8);
        return JSON.parse(stringData);
      }
      return null;
    } catch (e) {
      console.log(e);
      return null;
    }
  }
}