import { Injectable } from '@angular/core';
import { Annotorious } from '@recogito/annotorious';
import '@recogito/annotorious/dist/annotorious.min.css';
import { AnnotationServerService } from '@services/annotation.server.service';

@Injectable({
  providedIn: 'root'
})
export class AnnotationService {
  private anno: Annotorious | null = null;

  constructor() {}

  initialize(imageElement: HTMLElement, eventHandlers: any): void {
    if (this.anno) {
      this.destroy(); // Destroy any existing instance
    }
    this.anno = new Annotorious({
      image: imageElement,
      widgets: [
        'COMMENT',
        { 
          widget: 'TAG',
          vocabulary: [ 
            { label: 'Place', uri: 'http://www.example.com/ontology/place' },
            { label: 'Person', uri: 'http://www.example.com/ontology/person' }, 
            { label: 'Event', uri: 'http://www.example.com/ontology/event' }
          ] 
        }
      ]
    });

    // Define valid event names
    const validEvents = ['createAnnotation', 'updateAnnotation', 'deleteAnnotation', 'selectAnnotation', 'cancelSelected', 'mouseEnterAnnotation', 'mouseLeaveAnnotation'];

    // Register the custom event handlers if they are valid
    if (eventHandlers) {
      for (const [event, handler] of Object.entries(eventHandlers)) {
        if (validEvents.includes(event)) {
          this.anno.on(event, handler);
        } else {
          console.warn(`Invalid event handler: ${event}`);
        }
      }
    }
  }

  getAnnotationById(id): any {
    return this.anno ? this.getAnnotationById(id) : {}
  }

  getAnnotations(): any[] {
    return this.anno ? this.anno.getAnnotations() : [];
  }

  setAnnotations(annotations: any[]): void {
    if (this.anno) {
      this.anno.setAnnotations(annotations);
    }
  }

  destroy(): void {
    if (this.anno) {
      this.anno.destroy();
      this.anno = null;
    }
  }
}
