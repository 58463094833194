import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { ToastDisplay } from '../toastr/toast-service';
import { UtilsHelper } from '../utils.helper';
import { AuthService } from '@services/auth.service';
import { CommonService } from '@services/common.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DashboardAuthGuard implements CanActivate {
  constructor(
    private _router: Router,
    private toastr: ToastDisplay,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private loading: NgxUiLoaderService,
    private commonService: CommonService
  ) { }

  canActivate(): boolean {
    debugger;
    if (UtilsHelper.IsLoggedIn) {
      console.log('Logged in');
      return true;
    }
    if (!UtilsHelper.getObject('logout')) {
      this.toastr.showError('You are not logged in');
    }
    this._router.navigate(['auth/login']);
  }

  canLoad(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const searchParams = new URLSearchParams(window.location.search);
    const authToken = searchParams.get('auth');
    if (authToken && !UtilsHelper.IsLoggedIn) {
      console.log('Found auth in url params');
      return this.tryLogin(authToken).pipe(
        map((success) => {
          if (success) {
            // Delete the 'auth' query parameter
            searchParams.delete('auth');

            // Reconstruct the URL with updated search parameters
            window.location.search = searchParams.toString();

            return this._router.createUrlTree([window.location.toString()]);
          } else {
            return this._router.createUrlTree(['auth/login']);
          }
        })
      );
    }

    if (UtilsHelper.IsLoggedIn) {
      return true;
    }

    if (!UtilsHelper.getObject('logout')) {
      this.toastr.showError('You are not logged in');
    }
    this._router.navigate(['auth/login']);
  }

  tryLogin(authToken: string): Observable<boolean> {
    this.loading.start();
    return new Observable<boolean>((observer) => {
      this.authService.validateAuthToken(authToken).subscribe(
        (res) => {
          console.log('res', res);
          debugger;
          if (res['status'] === 'success') {
            this.toastr.showSuccess('User logged in successfully');
            const userName = res['data'][0]['user']['name'];
            const userId = res['data'][0]['user']['id'];
            const userAvatar = res['data'][0]['user']['userAvatar'];
            UtilsHelper.setObject('token', authToken);
            UtilsHelper.setObject('userName', userName);
            UtilsHelper.setObject('userId', userId);
            UtilsHelper.setObject('userAvatar', userAvatar);
            UtilsHelper.removeObject("logout");
            this.commonService.profileName$.next(userName);
            this.loading.stop();
            observer.next(true);
            observer.complete();
          } else {
            this.loading.stop();
            observer.next(false);
            observer.complete();
          }
        },
        (err) => {
          this.loading.stop();
          observer.next(false);
          observer.complete();
        }
      );
    });
  }
}
