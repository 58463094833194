import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import {
  HttpErrorResponse,
  HttpInterceptor,
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { tap } from "rxjs/operators";
import { ToastDisplay } from "../toastr/toast-service";
import { UtilsHelper } from "../utils.helper";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { AuthService } from "@services/auth.service";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {

  urlNotToAddHeader = ['register', 'login', 'password/forgot','directline/conversations', 'password/reset', 'user/verify', 'call/invite', 'call/add.participants', '/auth/validate.token' ];
  private unsubscribe$ = new Subject<void>();
  constructor(
    private toastr: ToastDisplay,
    private loader: NgxUiLoaderService,
    private authService: AuthService,
    private router:Router
  ) {}

  refreshToken() {
    this.authService.refreshToken().pipe(takeUntil(this.unsubscribe$)).subscribe((data: any) => {
      if(data['status'] == 'success'){
        const token = data['data'][0].refresh_token;
        UtilsHelper.setObject("token",token);
        console.log('Token refreshed');
      }else{
        UtilsHelper.clearAll();
        UtilsHelper.setObject("logout", true);
        location.reload();
      }
    }, (error) => {
     // console.log(error);
    });
  }


  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.urlNotToAddHeader.findIndex(f => request.url.includes(f)) == -1) {
      if(!UtilsHelper.getToken()){
        this.logout();
      }
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${UtilsHelper.getToken()}`
        }
      });
    }
    if( request.url.includes('password/reset')){
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${UtilsHelper.getObject('authTkn')}`
        }
      });
    }
    if(request.url.includes('/user/verify')){
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${UtilsHelper.getObject('verifyUserToken')}`
        }
      });
    }
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // do stuff with response if you want.
            let token = UtilsHelper.getToken();
            if (token) {
              const tokenPayload = JSON.parse(atob(token.split('.')[1]));
              const issued = tokenPayload.iat;
              const expiry = tokenPayload.exp;
              const now = Math.floor((new Date).getTime() / 1000);
              //if token expiring in 5 minutes then refresh it
              if (now >= (expiry - 300)) {
                console.log('expiring in 5 minutes');
                this.refreshToken();
              } else if (now >= expiry) {
                console.log('expired');
                //expired
                UtilsHelper.clearAll();
                UtilsHelper.setObject("logout", true);
                this.router.navigate(['/']);
              } 
              // Token is already getting refreshed is expiry time is within 5 minutes
              // else {
              //   let time = ((expiry - issued) - 300) * 1000;
              //   console.log(time);
              //   setTimeout(() => {
              //     this.refreshToken();
              //   }, time);
              // }
            }
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if(err.status == 0){
              this.toastr.showInformation("You are offline, please check your internet connection")
            }else{
              if(err.url.includes('/user/verify')){
              }
              else if(err.status === 403){
                this.logout();
              }else{
                if(err.error["message"]){
                  if(err.error['message'].includes('already invited') || err.error['message'].includes('already added')){
                    this.toastr.showInformation(err.error["message"])
                  }else{
                    this.toastr.showError(err.error["message"])
                  }
                }
              }
              this.loader.stop();
            }
          }
        }
      )
    );
  }

  logout(){
    if(UtilsHelper.getAuthToken()){
      this.authService.logoutAPI().subscribe(
        res=>{
          UtilsHelper.clearAll();
          UtilsHelper.setObject("logout", true);
          this.toastr.showSuccess('Logout successfully.');
          if (window.location.pathname != '/auth/login') {
            window.location.reload();
          }
        }, err=>{
          UtilsHelper.clearAll();
          UtilsHelper.setObject("logout", true);
          this.toastr.showSuccess('Logout successfully.');
          if (window.location.pathname != '/auth/login') {
            window.location.reload();
          }
        }
      )
    }else{
      UtilsHelper.clearAll();
      UtilsHelper.setObject("logout", true);
      this.toastr.showSuccess('Logout successfully.');
      if (window.location.pathname != '/auth/login') {
        // window.location.reload();
        this.router.navigate(['auth/login']);
      }
    }
  }
}
