import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterAttachmentIcon'
})
export class FilterAttachmentIconPipe implements PipeTransform {

  fileTypes: string[] = ["png", "jpg", "jpeg", "mp4", "3gp", "mkv", "xls", "xlsx", "docx", "doc", "pdf", "webp"];

  transform(fileName: string): number {
    if(fileName){
      return this.fileTypes.filter(f => fileName.toLowerCase().includes(f)).length;
    }
    return 0;
  }

}
