import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userProfileImage',
})
export class UserProfileImgPipe implements PipeTransform {

  public transform(currentUser, userList, filterType) {
    if(filterType){
      if(currentUser && userList && userList.length){
        let url = userList.filter(f => f.userID == currentUser).map(m => (filterType == 'photo')? m.photo : m.name);
        if(url && url.length){
          return url[0];
        }
      }
      return currentUser;
    }
    return currentUser;
  }

}
