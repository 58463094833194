import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageNotFoundComponent } from '@common-components/page-not-found/page-not-found.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { SearchUserPipe } from '@pipes/search-user.pipe';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { MomentTimezonePickerModule } from 'moment-timezone-picker';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { NgxUiLoaderConfig, NgxUiLoaderModule } from 'ngx-ui-loader';
import * as loader from './json/loader.json';
import { CalendarMeetingDaywisePipe } from '@pipes/calendar-meeting-daywise.pipe';
import { SearchInChatPipe } from '@pipes/search-in-chat.pipe';
import { JwPaginationModule } from 'jw-angular-pagination';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { NgAudioRecorderModule, OutputFormat } from 'ng-audio-recorder';
import { SpeechToTextComponent } from './common-components/speech-to-text/speech-to-text.component';
import { TimestampToAgoPipe } from './pipes/timestamp-to-ago.pipe';
import { UserProfileImgPipe } from '@pipes/user-profile-img.pipe';
import { FilterAttachmentIconPipe } from './pipes/filter-attachment-icon.pipe';
import { UserProfileModalComponent } from '@common-components/user-profile-modal/user-profile.component';
import { UserProfileComponent } from '@common-components/user-profile/user-profile.component';
import { SafePipe } from './pipes/safe.pipe';
const PIPES = [SearchUserPipe, CalendarMeetingDaywisePipe, SearchInChatPipe, TimestampToAgoPipe, UserProfileImgPipe, FilterAttachmentIconPipe, SafePipe];
const COMPONENTS = [];
const FORMS = [
  ReactiveFormsModule,
  FormsModule,
];

@NgModule({
  declarations: [...PIPES, ...COMPONENTS, PageNotFoundComponent, ConfirmationDialogComponent, SpeechToTextComponent, UserProfileComponent, UserProfileModalComponent ],
  imports: [
    CommonModule,
    MomentTimezonePickerModule,
    NgxUiLoaderModule.forRoot( loader.loadingConfigs as NgxUiLoaderConfig ),
    ...FORMS,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    OwlNativeDateTimeModule,
    OwlDateTimeModule,
    JwPaginationModule,
    PickerModule,
    NgAudioRecorderModule
  ],
  exports: [
    CommonModule,
    NgxUiLoaderModule,
    MomentTimezonePickerModule,
    ...PIPES,
    ...COMPONENTS,
    ...FORMS,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    OwlNativeDateTimeModule,
    OwlDateTimeModule,
    JwPaginationModule,
    PickerModule,
    NgAudioRecorderModule,
    SpeechToTextComponent,
    UserProfileComponent,
    UserProfileModalComponent
  ],
})

export class SharedModule { }
