import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timestampToAgo'
})
export class TimestampToAgoPipe implements PipeTransform {

  transform(previous, refreshCount): string {
    let msPerMinute = 60 * 1000;
    let msPerHour = msPerMinute * 60;
    let msPerDay = msPerHour * 24;
    let msPerMonth = msPerDay * 30;
    let msPerYear = msPerDay * 365;

    let elapsed = new Date().getTime() - new Date(previous).getTime();

    if (elapsed < msPerMinute) {
         return Math.round(elapsed/1000) + ' seconds ago';
    }

    else if (elapsed < msPerHour) {
      return Math.round(elapsed/msPerMinute) + ' minutes ago';
    }

    else if (elapsed < msPerDay ) {
      if((elapsed/msPerHour) > 2){
        return "today, "+ moment(new Date(previous)).format("hh:mm");
      }else{
        return Math.round(elapsed/msPerHour ) + ' hours ago';
      }
    }

    else if (elapsed < msPerMonth) {
         return moment(new Date(previous)).format("DD MMMM, hh:mm");
    }

    else if (elapsed < msPerYear) {
        return moment(new Date(previous)).format("DD MMMM, hh:mm");
    }

    else {
      return moment(new Date(previous)).format("DD MMMM YYYY, hh:mm");
    }
  }
}
